import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route, } from 'react-router-dom'
import { Spin } from 'antd'
import KeepAlive from 'react-activation'
import App from '../App'
import Login from '../pages/Login'
import Www from '../pages/Www'


const router_arr = [
    {
        path: '/', component: App, children: [
            { path: '/', component: lazy(() => import("../pages/Home")), keepAlive: false },
            { path: '/gg', component: lazy(() => import("../pages/Home/Gg")), keepAlive: false },
            { path: '/batch_departure', component: lazy(() => import("../pages/Home/batch_departure")), keepAlive: false },
            { path: '/detail', component: lazy(() => import("../pages/Waybill/Batch_departure/Detail")), keepAlive: false },
            { path: '/detail_list', component: lazy(() => import("../pages/Home/detail_list")), keepAlive: false },
            { path: '/transfer', component: lazy(() => import("../pages/Home/Transfer")), keepAlive: false },
            { path: '/transfer2', component: lazy(() => import("../pages/Home/Transfer2")), keepAlive: false },
            { path: '/role/role_admin', component: lazy(() => import("../pages/Role/Role_admin/index")), keepAlive: true },
            { path: '/role/role_admin/edit', component: lazy(() => import("../pages/Role/Role_admin/Edit")), keepAlive: false },
            { path: '/role/deliver_admin', component: lazy(() => import("../pages/Role/Deliver_admin")), keepAlive: true },
            { path: '/role/deliver_admin/addUser', component: lazy(() => import("../pages/Role/Deliver_admin/Add_user")), keepAlive: false },
            { path: '/role/destination_admin', component: lazy(() => import("../pages/Role/Destination_admin")), keepAlive: true },
            { path: '/role/destination_admin/addAddress', component: lazy(() => import("../pages/Role/Destination_admin/Add_address")), keepAlive: false },
            { path: '/role/destination_admin/edit', component: lazy(() => import("../pages/Role/Destination_admin/Detail")), keepAlive: false },
            { path: '/role/driver_list', component: lazy(() => import("../pages/Role/Driver_list")), keepAlive: false },
            { path: '/role/driver_list/add_driver', component: lazy(() => import("../pages/Role/Driver_list/Add_driver")), keepAlive: false },
            { path: '/role/user_admin', component: lazy(() => import("../pages/Role/User_admin")), keepAlive: false },
            { path: '/role/user_admin/edit', component: lazy(() => import("../pages/Role/User_admin/Edit")), keepAlive: false },
            { path: '/role/user_admin/add_admin', component: lazy(() => import("../pages/Role/User_admin/Add_admin")), keepAlive: false },
            { path: '/often/often', component: lazy(() => import("../pages/Role/often")), keepAlive: false },
            { path: '/often/often/add', component: lazy(() => import("../pages/Role/often/Add")), keepAlive: false },
            { path: '/often/often/edit', component: lazy(() => import("../pages/Role/often/Edit")), keepAlive: false },
            { path: '/role/qiye_admin', component: lazy(() => import("../pages/Role/qiye_admin")), keepAlive: false },

            { path: '/waybill/waybill_about', component: lazy(() => import("../pages/Waybill/Waybill_about")), keepAlive: true },
            { path: '/waybill/waybill_about/add', component: lazy(() => import("../pages/Waybill/Waybill_about/Add")), keepAlive: false },
            { path: '/waybill/waybill_about/bl_jilu', component: lazy(() => import("../pages/Waybill/Waybill_about/Bl_jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/zk_jilu', component: lazy(() => import("../pages/Waybill/Waybill_about/Zk_jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/jg_jilu', component: lazy(() => import("../pages/Waybill/Waybill_about/jg_jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/xj_jilu', component: lazy(() => import("../pages/Waybill/Waybill_about/xj_jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/bx_jilu', component: lazy(() => import("../pages/Waybill/Waybill_about/bx_jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/wx_jilu', component: lazy(() => import("../pages/Waybill/Waybill_about/wx_jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/jl_jilu', component: lazy(() => import("../pages/Waybill/Waybill_about/jl_jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/js_jilu', component: lazy(() => import("../pages/Fenxi/jishi/jilu")), keepAlive: false },
            { path: '/waybill/waybill_about/import', component: lazy(() => import("../pages/Waybill/Waybill_about/import")), keepAlive: false },

            { path: '/waybill/waybill_about/edit', component: lazy(() => import("../pages/Waybill/Waybill_about/Edit")), keepAlive: false },
            { path: '/waybill/waybill_about/print', component: lazy(() => import("../pages/Waybill/Ticket_making/Print")), keepAlive: false },
            { path: '/waybill/waybill_about/print2', component: lazy(() => import("../pages/Waybill/Ticket_making/Print2")), keepAlive: false },
            { path: '/waybill/waybill_about/leading_in', component: lazy(() => import("../pages/Waybill/Waybill_about/Leading_in")), keepAlive: false },
            { path: '/waybill/waybill_about/sign', component: lazy(() => import("../pages/Waybill/Waybill_about/Sign")), keepAlive: false },
            { path: '/waybill/waybill_about/abnormal', component: lazy(() => import("../pages/Waybill/Waybill_about/Abnormal")), keepAlive: false },
            { path: '/waybill/waybill_about/depart', component: lazy(() => import("../pages/Waybill/Waybill_about//Depart")), keepAlive: false },
            { path: '/waybill/waybill_about/transfer', component: lazy(() => import("../pages/Waybill/Waybill_about/Transfer")), keepAlive: false },
            { path: '/waybill/waybill_about/derive', component: lazy(() => import("../pages/Waybill/Waybill_about/Derive")), keepAlive: false },
            { path: '/reach/daozhan', component: lazy(() => import("../pages/Waybill/daozhan/index")), keepAlive: false },
            { path: '/reach/query', component: lazy(() => import("../pages/Waybill/daozhan/query")), keepAlive: false },
            { path: '/reach/daozhan/batch_departure', component: lazy(() => import("../pages/Waybill/daozhan/batch_departure")), keepAlive: false },
            { path: '/reach/daozhan/detail_list', component: lazy(() => import("../pages/Waybill/daozhan/detail_list")), keepAlive: false },
            { path: '/reach/daozhan/transfer', component: lazy(() => import("../pages/Waybill/daozhan/Transfer")), keepAlive: false },
            { path: '/reach/daozhan/transfer2', component: lazy(() => import("../pages/Waybill/daozhan/Transfer2")), keepAlive: false },
            { path: '/reach/daozhan/abnormal', component: lazy(() => import("../pages/Waybill/Waybill_about/Abnormal")), keepAlive: false },
            { path: '/reach/daozhan/sign', component: lazy(() => import("../pages/Waybill/Waybill_about/Sign")), keepAlive: false },

            { path: '/money/supplier/addInfo', component: lazy(() => import("../pages/Waybill/Supplier/AddInfo")), keepAlive: false },
            { path: '/money/supplier/addInfo2', component: lazy(() => import("../pages/Waybill/Supplier/AddInfo2")), keepAlive: false },
            { path: '/money/supplier/addInfo3', component: lazy(() => import("../pages/Waybill/Supplier/AddInfo3")), keepAlive: false },
            { path: '/money/supplier', component: lazy(() => import("../pages/Waybill/Supplier")), keepAlive: false },
            { path: '/money/supplier/detail', component: lazy(() => import("../pages/Waybill/Supplier/Detail")), keepAlive: false },
            { path: '/money/supplier_list', component: lazy(() => import("../pages/Waybill/Supplier_list")), keepAlive: false },
            { path: '/money/supplier_list/checkStatus', component: lazy(() => import("../pages/Waybill/Supplier_list/CheckStatus")), keepAlive: false },
            { path: '/money/supplier_list/detail', component: lazy(() => import("../pages/Waybill/Supplier_list/Detail")), keepAlive: false },
            { path: '/money/supplier_list/xq', component: lazy(() => import("../pages/Waybill/Supplier_list/Xq")), keepAlive: false },
            { path: '/money/supplier_list/print', component: lazy(() => import("../pages/Waybill/Supplier_list/Print")), keepAlive: false },
            { path: '/money/checkList', component: lazy(() => import("../pages/Waybill/Supplier_list/CheckList")), keepAlive: false },
            { path: '/money/checkList/detail', component: lazy(() => import("../pages/Waybill/Supplier_list/ChectListDetail")), keepAlive: false },
            { path: '/money/checkList/caroDetail', component: lazy(() => import("../pages/Waybill/Supplier_list/Xq")), keepAlive: false },

            { path: '/money/supplier2/addInfo', component: lazy(() => import("../pages/Waybill/Supplier/AddInfo")), keepAlive: false },
            { path: '/money/supplier2/addInfo2', component: lazy(() => import("../pages/Waybill/Supplier/AddInfo2")), keepAlive: false },
            { path: '/money/supplier2', component: lazy(() => import("../pages/Waybill/Supplier/index2")), keepAlive: false },
            { path: '/money/supplier2/checkInfo', component: lazy(() => import("../pages/Waybill/Supplier/CheckInfo")), keepAlive: false },
            { path: '/money/supplier2/detail', component: lazy(() => import("../pages/Waybill/Supplier/Detail")), keepAlive: false },
            { path: '/money/supplier_list2', component: lazy(() => import("../pages/Waybill/Supplier_list")), keepAlive: false },
            { path: '/money/supplier_list2/detail', component: lazy(() => import("../pages/Waybill/Supplier_list/Detail")), keepAlive: false },
            { path: '/money/supplier_list2/xq', component: lazy(() => import("../pages/Waybill/Supplier_list/Xq")), keepAlive: false },
            { path: '/money/supplier_list2/print', component: lazy(() => import("../pages/Waybill/Supplier_list/Print")), keepAlive: false },
            { path: '/waybill/applyDel_list', component: lazy(() => import("../pages/Waybill/ApplyDel_list")), keepAlive: false },
            { path: '/waybill/applyDel_list/detail', component: lazy(() => import("../pages/Waybill/ApplyDel_list/Detail")), keepAlive: false },
            { path: '/waybill/abnormal_admin', component: lazy(() => import("../pages/Waybill/Abnormal_admin")), keepAlive: false },
            { path: '/waybill/abnormal_admin/edit', component: lazy(() => import("../pages/Waybill/Abnormal_admin/Edit")), keepAlive: false },
            { path: '/waybill/abnormal_admin/deal', component: lazy(() => import("../pages/Waybill/Abnormal_admin/Deal")), keepAlive: false },
            { path: '/waybill/sign_admin', component: lazy(() => import("../pages/Waybill/Sign_admin")), keepAlive: true },
            { path: '/waybill/sign_admin/edit', component: lazy(() => import("../pages/Waybill/Sign_admin/Edit")), keepAlive: false },
            { path: '/waybill/sign_admin/add', component: lazy(() => import("../pages/Waybill/Sign_admin/Add")), keepAlive: false },
            { path: '/waybill/ticket_making', component: lazy(() => import("../pages/Waybill/Ticket_making")), keepAlive: false },
            { path: '/waybill/ticket_making/print', component: lazy(() => import("../pages/Waybill/Ticket_making/Print")), keepAlive: false },
            { path: '/waybill/ticket_making/print2', component: lazy(() => import("../pages/Waybill/Ticket_making/Print2")), keepAlive: false },
            { path: '/waybill/batch_departure', component: lazy(() => import("../pages/Waybill/Batch_departure")), keepAlive: false },
            { path: '/waybill/batch_departure/detail', component: lazy(() => import("../pages/Waybill/Batch_departure/Detail")), keepAlive: false },
            { path: '/waybill/batch_departure/detail_list', component: lazy(() => import("../pages/Waybill/Batch_departure/Deatil_list")), keepAlive: false },
            { path: '/reach/transfer_list', component: lazy(() => import("../pages/Waybill/Transfer_list")), keepAlive: false },
            { path: '/reach/jiesuan_list', component: lazy(() => import("../pages/Waybill/JieSuan_list")), keepAlive: false },
            { path: '/waybill/batch_departure/sign', component: lazy(() => import("../pages/Waybill/Batch_departure/Sign")), keepAlive: false, },
            { path: '/settled/settled_list', component: lazy(() => import("../pages/Settled/Settled_list")), keepAlive: false,},
            { path: '/settled/settled_list/add', component: lazy(() => import("../pages/Settled/Settled_list/Add")), keepAlive: false, },
            { path: '/settled/settled_list/detail', component: lazy(() => import("../pages/Settled/Settled_list/Detail")), keepAlive: false, },
            { path: '/settled/statistics_list', component: lazy(() => import("../pages/Settled/Statistics_list")), keepAlive: false, },
            { path: '/settled/statistics_list/detail', component: lazy(() => import("../pages/Settled/Statistics_list/Detail")), keepAlive: false,  },
            { path: '/contract/contract_admin', component: lazy(() => import("../pages/Contract/Contract_admin")), keepAlive: false, },
            { path: '/contract/contract_admin/add', component: lazy(() => import("../pages/Contract/Contract_admin/Add")), keepAlive: false },
            { path: '/contract/contract_admin/edit', component: lazy(() => import("../pages/Contract/Contract_admin/Edit")), keepAlive: false },
            { path: '/announcement/announcement_admin', component: lazy(() => import("../pages/Announcement/Announcement_admin")), keepAlive: false },
            { path: '/announcement/announcement_admin/add', component: lazy(() => import("../pages/Announcement/Announcement_admin/Add")), keepAlive: false },
            { path: '/announcement/announcement_admin/edit', component: lazy(() => import("../pages/Announcement/Announcement_admin/Edit")), keepAlive: false },
            { path: '/regulations_admin/regulations', component: lazy(() => import("../pages/Announcement/Announcement_admin/Edit")), keepAlive: false },
            
            { path: '/fenxi/map', component: lazy(() => import("../pages/Fenxi/Map")), keepAlive: false },
            { path: '/fenxi/statistics', component: lazy(() => import("../pages/Fenxi/Statistics")), keepAlive: false },
            { path: '/fenxi/abnormal', component: lazy(() => import("../pages/Fenxi/Abnormal")), keepAlive: false },
            { path: '/fenxi/baoxiu', component: lazy(() => import("../pages/Fenxi/Baoxiu")), keepAlive: false },
            { path: '/fenxi/buliang', component: lazy(() => import("../pages/Fenxi/Buliang")), keepAlive: false },
            { path: '/fenxi/zhikong', component: lazy(() => import("../pages/Fenxi/Zhikong")), keepAlive: false },
            { path: '/fenxi/weixiu', component: lazy(() => import("../pages/Fenxi/Weixiu")), keepAlive: false },
            { path: '/fenxi/jiediao', component: lazy(() => import("../pages/Fenxi/Jiediao")), keepAlive: false },
            { path: '/fenxi/guihuan', component: lazy(() => import("../pages/Fenxi/Jiediao/guihuan")), keepAlive: false },
            { path: '/fenxi/xunjian', component: lazy(() => import("../pages/Fenxi/Xunjian")), keepAlive: false },
            { path: '/fenxi/jiliang', component: lazy(() => import("../pages/Fenxi/Jiliang")), keepAlive: false },
            { path: '/fenxi/jishi', component: lazy(() => import("../pages/Fenxi/jishi")), keepAlive: false },
            { path: '/fenxi/gongchengshi', component: lazy(() => import("../pages/Fenxi/gongchengshi")), keepAlive: false },
            
            { path: '/sorting/sorting_list', component: lazy(() => import("../pages/Sorting/Sorting_list")), keepAlive: false },
            { path: '/sorting/sorting_list/waybill', component: lazy(() => import("../pages/Sorting/Sorting_list/Waybill")), keepAlive: false },
            { path: '/sorting/sorting_list/waybill/edit', component: lazy(() => import("../pages/Waybill/Waybill_about/Edit")), keepAlive: false },

            { path: '/sorting/sorting_list/transfer', component: lazy(() => import("../pages/Sorting/Sorting_list/Transfer")), keepAlive: false },
            { path: '/sorting/sorting_list/transfer2', component: lazy(() => import("../pages/Sorting/Sorting_list/Transfer2")), keepAlive: false },
            { path: '/company/company_list', component: lazy(() => import("../pages/Company/Company_list")), keepAlive: false },
            { path: '/company/apply_list', component: lazy(() => import("../pages/Company/Apply_list")), keepAlive: true },
            { path: '/company/apply_list/add', component: lazy(() => import("../pages/Company/Apply_list/Add")), keepAlive: false },
            { path: '/company/bound_list', component: lazy(() => import("../pages/Company/Bound_list")), keepAlive: false },
            { path: '/driver/driver_list', component: lazy(() => import("../pages/Driver/Driver_list")), keepAlive: false },
            { path: '/invest/money', component: lazy(() => import("../pages/Invest/Money")), keepAlive: false },
            { path: '/invest/list', component: lazy(() => import("../pages/Invest/List")), keepAlive: false },
            { path: '/pay', component: lazy(() => import("../pages/Pay")), keepAlive: false },

            { path: '/area/money', component: lazy(() => import("../pages/area/money")), keepAlive: false },
            { path: '/area/money/add', component: lazy(() => import("../pages/area/money/Add")), keepAlive: false },
        ]
    },
    { path: '/login', component: Login, keepAlive: false },
    { path: '/quanping/dataV', component: lazy(() => import("../pages/Fenxi/dataV")), keepAlive: false },
    { path: '/settled', component: lazy(() => import("../pages/Login/Settled")), keepAlive: false },
    { path: '*', component: Www, keepAlive: false },
]

const Loading = () => {
    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <Spin size="large" />
        </div>
    )
}
const MyRouter = () => (
    <BrowserRouter>
        <Routes>
            {
                router_arr.map((item, index) => {
                    return (
                        item.children ?
                            <Route key={index} path={item.path} element={<item.component />}>
                                {
                                    item.children.map((item2, index2) => <Route key={index2} path={item2.path} element={
                                        <Suspense fallback={<Loading />}>
                                            {
                                                item2.path.split('/').length == 3 ?
                                                    // <KeepAlive name={item2.path} >
                                                    <KeepAlive id={index2} name={item2.path} when={() => true} >
                                                        <item2.component />
                                                    </KeepAlive>
                                                    :
                                                    <KeepAlive id={index2} when={() => false}>
                                                        <item2.component />
                                                    </KeepAlive>
                                            }

                                        </Suspense>
                                    }></Route>)
                                }
                            </Route>

                            :
                            <Route key={index} path={item.path} element={
                                <Suspense fallback={<Loading />}>
                                    <item.component /></Suspense>}></Route>
                    )
                })
            }
        </Routes>

    </BrowserRouter>
)

export default MyRouter