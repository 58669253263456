import React, { useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, message, Checkbox } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { login } from '../../api/api'
import './index.scss'

const Login = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  useEffect(() => {
    const re = new RegExp("\s?" + "isLogin" + "=([^;]+)(;|$)")
    if (document.cookie.match(re) && document.cookie.match(re)[1] === 'true' && localStorage.getItem('id')) return navigate('/')
    // if (localStorage.getItem('id')) return navigate('/')
  })
  const onFinish = (values) => {
    console.log('Success:', values)
    login(values).then(res => {
      console.log(res);
      localStorage.setItem('id', res.data.id)
      if (JSON.stringify(res.data.shop_name) === '{}' || !res.data.shop_name) {
        res.data.shop_name = ''
      }
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      // if (values.remember) {
      //   localStorage.setItem('loginInfo', JSON.stringify(values))
      // } else if (localStorage.getItem('loginInfo')) {
      //   localStorage.removeItem('loginInfo')
      // }
      let data = new Date().getTime()   //先将此时的时间转化为毫秒
      let new_data = new Date(data + 12 * 60 * 60 * 1000)  //将过期时间设置为12小时后
      // toUTCString() 是将时间根据世界时转换为字符串
      document.cookie = 'isLogin=true;' + 'expires=' + new_data.toUTCString()
      message.success('登录成功')
      navigate('/')
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  // 申请入驻
  const goPages = () => {
    navigate('/settled')
  }

  return (
    <div className='bg'>
      <div className='login'>
        <h1>登录系统</h1>
        <Form
          className='form'
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          form={form}
        >
          <h3>用户登录</h3>
          <Form.Item
            // label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
            className="input"
          >
            <Input size="large" placeholder="请输入用户名" prefix={<UserOutlined />} />
          </Form.Item>

          <Form.Item
            // label="密码"
            className="input"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input.Password size="large" placeholder="请输入密码" prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            name="num"
            rules={[{ required: true, message: '请输入医院编号' }]}
            className="input"
          >
            <Input size="large" placeholder="请输入医院编号" prefix={<UserOutlined />} />
          </Form.Item>


          {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox style={{ color: '#fff' }}>记住我的登录信息</Checkbox>
          </Form.Item> */}

          <div style={{ margin: '0 0 16px 0' }}>
            <a onClick={goPages}>系统申请</a>
          </div>

          <Form.Item >
            <Button style={{ width: '100%', height: 40 }} type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Login;
