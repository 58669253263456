
import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Layout } from 'antd';
import KeepAlive from 'react-activation'
import './App.scss';
import Top from './component/Top'
import Left from './component/Left'
import Tabs from './component/Tabs'


const { Content, Footer } = Layout;



function App() {
  const navigate = useNavigate() // 跳转
  const location = useLocation()
  let re = new RegExp("\s?" + "isLogin" + "=([^;]+)(;|$)")
  useEffect(() => {
    if ((!document.cookie.match(re) || document.cookie.match(re)[1] != 'true') && location.search.indexOf('?dev_id') === -1) {
      localStorage.clear()
      sessionStorage.clear()
      navigate('/login')
      return
    }
  }, [])
  if (!localStorage.getItem('id')) navigate('/login') // 未登录
  return (
    <Layout style={{ height: '100vh', minWidth: '1000px'}}>
      <Top />
      <Layout>
        <Left />
        <Content style={{ overflow: 'auto', background: '#fff', padding: '40px 0 0' }}>
          
          {/* <div style={{ overflow: 'hidden', overflowY: 'scroll', height: 'calc(100% - 40px)' }}> */}
          <Outlet />
          {/* </div> */}
          {/* <KeepAlive> */}
          {/* </KeepAlive> */}
          {/* <Footer>Footer</Footer> */}
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
